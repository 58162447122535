import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";
import { filter, map } from 'rxjs/operators';
import { BrowserAuthService } from "../_services/browser-auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router,  private browserAuthService: BrowserAuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		 const currentUrl = state.url;
		 const componentNameURL = currentUrl.split('/')[1];
		 if(componentNameURL=='auto-technical-report' || componentNameURL=='atuo-report-detail'){
			return true;
		 }

    }
}