import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../auth/_guards/auth.guard";

var expectedRole: any = [];
const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],

        "children": [

          { 
                "path": "auto-technical-report/:serverName/:key/:pageNo",
                //"canActivate": [AuthGuard],
				"loadChildren": ".\/pages\/default\/components\/auto-technical-report\/report.module#ReportModule"
            },
			{ 
                "path": "atuo-report-detail/:serverName/:key/:pageNo",
                //"canActivate": [AuthGuard],
				"loadChildren": ".\/pages\/default\/components\/atuo-report-detail\/report.module#ReportModule"
            },
            
            {
                "path": "generate-report",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/generate-report\/report.module#ReportModule"
            },
            {
                "path": "normal-generate-report",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/normal-generate-report\/report.module#ReportModule"
            },
			
            {
                "path": "header\/profile",
                "loadChildren": ".\/pages\/default\/header\/header-profile\/header-profile.module#HeaderProfileModule"
            },
            {
                "path": "404",
                "loadChildren": ".\/pages\/default\/not-found\/not-found.module#NotFoundModule"
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "snippets\/pages\/errors\/error-6",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-6\/errors-error-6.module#ErrorsError6Module"
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThemeRoutingModule { }